/**
 * Created by LinK on 2017/1/19.
 */
export const ID = '@custom-dimension'

export default {
  ID: ID,
  GET_CUSTOM_RECORD: `${ID}/GET_CUSTOM_RECORD`,
  POST_CUSTOM_RECORD: `${ID}/POST_CUSTOM_RECORD`,
  PUT_CUSTOM_RECORD: `${ID}/PUT_CUSTOM_RECORD`,
  STOP_CUSTOM_RECORD: `${ID}/STOP_CUSTOM_RECORD`,
  START_CUSTOM_RECORD: `${ID}/START_CUSTOM_RECORD`,
  GET_DIMENSION_ITEM: `${ID}/GET_DIMENSION_ITEM`,
  GET_DIMENSION_ITEM_DISABLED: `${ID}/GET_DIMENSION_ITEM_DISABLED`,
  GET_ALL_DIMENSION_ITEMS: `${ID}/GET_ALL_DIMENSION_ITEMS`,
  POST_DIMENSION_ITEM: `${ID}/POST_DIMENSION_ITEM`,
  PUT_DIMENSION_ITEM: `${ID}/PUT_DIMENSION_ITEM`,
  DELETE_DIMENSION_ITEM: `${ID}/DELETE_DIMENSION_ITEM`,
  STOP_DIMENSION_ITEM: `${ID}/STOP_DIMENSION_ITEM`,
  RESTORE_DIMENSION_ITEM: `${ID}/RESTORE_DIMENSION_ITEM`,
  IMPORT_DIMENSION_ITEM: `${ID}/IMPORT_DIMENSION_ITEM`,
  EXPORT_DIMENSION_ITEM: `${ID}/EXPORT_DIMENSION_ITEM`,
  GET_DEPARTMENT_BY_ITEM_ID: `${ID}/GET_DEPARTMENT_BY_ITEM_ID`,
  ENUMERATE: `${ID}/ENUMERATE`,
  ENUMERATE_ITEMS: `${ID}/ENUMERATE_ITEMS`,
  BATCH_VISIBILITY: `${ID}/BATCH_VISIBILITY`,

  GET_DIMENSION_ITEM_DETAIL: `${ID}/GET_DIMENSION_ITEM_DETAIL`
}
