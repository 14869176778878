/**
 * Created by LinK on 2017/1/19.
 */
// @i18n-ignore-all

import { app as api } from '@ekuaibao/whispered'

import * as actions from './custom-dimension-action'

import { ID } from './key'
import { getDimensionConfig } from './fetchUtils'

export default [
  {
    id: '@custom-dimension',
    reducer: () => import('./custom-dimension-reducer'),
    path: '/custom-dimension',
    ref: '/',
    onload: () => import('./custom-dimension-view'),
    onbefore: async (app) => {
      const CustomDimensionItemModal = await import('./models/CustomDimensionItemModal')
      const ProjectFormModal = await import('./models/ProjectFormModal')
      app.collection.register(CustomDimensionItemModal.default)
      app.collection.register(ProjectFormModal.default)
    },
    'get:enumerate:dimension'() {
      return api.dispatch(actions.getEnumerateDimension())
    },
    'list:custom:records'(params) {
      return api.dispatch(actions.getCustomRecord(params))
    },

    'list:all:custom:records'(params) {
      return api.dispatch(actions.getAllCustomRecord(params))
    },

    'get:DimensionItems'(value) {
      return api.dispatch(actions.getDimensionItem(value))
    },

    'get:DimensionItemDetail'(value) {
      return api.dispatch(actions.getDimensionItemDetail(value))
    },
    'get:DimensionItemsList'(value) {
      const result = actions.getDimensionItem(value).payload
      return result
    },
    'check:Dimensions:isLeaf': async (ids) => {
      const result = await actions.checkDimensionIsLeaf(ids)
      return result
    },
    'get:dimension:config:by:id': async (id) => {
      const result = await getDimensionConfig(id)
      return result
    },

    'import:actions': () => actions,

    'import:ID': () => ID,
  },
  {
    id: 'enumArchives',
    path: '/enumerate-dimension',
    ref: '/',
    onload: () => import('./enumerate/enumerate-dimension-view'),
  },
  {
    point: '@@menus',
    onload: () => [
      {
        id: 'custom-dimension',
        pId: 'enterprise-manage',
        permissions: ['SYS_ADMIN'],
        weight: 9.5,
        label: '扩展档案',
        href: '/custom-dimension',
        icon: 'menu-custom-project',
      },
    ],
  },
  {
    point: '@@layers',
    prefix: '@custom-dimension',
    onload: () => import('./layer'),
  },
]
