/**
 * Created by LinK on 2017/1/19.
 */
import key from './key'
import { Resource } from '@ekuaibao/fetch'

const customReport = new Resource('/api/v1/basedata/dimensions')
const dimensionItem = new Resource('/api/v1/basedata/dimensionItems')
const properySet = new Resource('/api/flow/v2/propertySet')
const enumerate = new Resource('/api/v1/basedata/enums')
const enumerateContent = new Resource('/api/v1/basedata/enumItems/byEnumCode')

export const enumrateExportExcel = '/api/v1/basedata/enums/excel/export'

const enumerateItems = new Resource('/api/v1/basedata/enumItems')

export function getCustomRecord(args = {}) {
  const { preset } = args
  let presetFilter = undefined
  if (preset !== undefined) {
    presetFilter = `preset==${preset ? 1 : 0}`
  }
  return getAllCustomRecord({ ...args, presetFilter })
}

export function getAllCustomRecord(args) {
  const isLoadAll = !(args && args.count)
  const { orgId = '', start = 0, count = 1000, searchStr, presetFilter } = args || {}
  const params = {
    limit: { start, count },
    select: 'id,label,code,name,state,type,active',
    orderBy: [{ value: 'createTime', order: 'DESC' }],
    filterBy: presetFilter,
  }
  if (searchStr) {
    const filterBy = params['filterBy']
    params['filterBy'] = filterBy
      ? `${filterBy}&&(name.contains("${searchStr}")||code.contains("${searchStr}"))`
      : `(name.contains("${searchStr}")||code.contains("${searchStr}"))`
  }
  const getAllCustomRecord = (pages = 1, totalItem = []) => {
    const {
      limit: { count: pageSize },
    } = params
    return customReport
      .POST('/active', params, {
        orgId,
        join: `id,organizationData,/authority/v2/action/share/show/$DIMENSION`,
      })
      .then((resp) => {
        if (resp.items.length === pageSize) {
          params.limit.start = pages * pageSize
          return getAllCustomRecord(++pages, totalItem.concat(resp.items))
        }
        return { items: totalItem.concat(resp.items), count: resp.count }
      })
  }
  return {
    type: key.GET_CUSTOM_RECORD,
    payload: isLoadAll
      ? getAllCustomRecord()
      : customReport.POST('/active', params, {
          orgId,
          join: `id,organizationData,/authority/v2/action/share/show/$DIMENSION`,
        }),
  }
}

//创建档案
export function postCustomRecord({ data, orgId }, done) {
  return {
    type: key.POST_CUSTOM_RECORD,
    payload: customReport.POST('', data, { orgId }),
    done,
  }
}

//修改档案
export function putCustomRecord({ data, orgId }, done) {
  return {
    type: key.PUT_CUSTOM_RECORD,
    payload: customReport.PUT('/$id', data, { orgId }),
    done,
  }
}

//停用项目
export function stopCustomReport({ id, orgId }, done) {
  return {
    type: key.STOP_CUSTOM_RECORD,
    payload: customReport.PUT('/$id/disable', { id }, { orgId }),
    done,
  }
}

//启用项目
export function startCustomReport({ data, orgId }, done) {
  return {
    type: key.START_CUSTOM_RECORD,
    payload: customReport.PUT('/$id/restore', data, { orgId }),
    done,
  }
}

//档案项相关
export function getDimensionItem(data, done) {
  return {
    type: key.GET_DIMENSION_ITEM,
    payload: dimensionItem.GET('/byDimensionId/$id/active', data),
    done,
  }
}

export function getDimensionItemDisabled(data, done) {
  return {
    type: key.GET_DIMENSION_ITEM_DISABLED,
    payload: dimensionItem.GET('/byDimensionId/$id', data),
    done,
  }
}

export function getAllDimension(data) {
  return {
    type: key.GET_ALL_DIMENSION_ITEMS,
    payload: dimensionItem.GET('/byDimensionId/$id', { ...data }),
    input: data,
  }
}

export function clearReduxDimensions() {
  return {
    type: key.GET_ALL_DIMENSION_ITEMS,
    payload: Promise.resolve({ items: [] }),
  }
}

export function saveDimensionItem(data, done) {
  const { item, orgId } = data
  return {
    type: key.POST_DIMENSION_ITEM,
    payload: dimensionItem.POST('', item, { orgId }),
    done,
  }
}

export function updateDimensionItem(data, done) {
  const { item, orgId } = data
  return {
    type: key.PUT_DIMENSION_ITEM,
    payload: dimensionItem.PUT('/$id', item, { orgId }),
    done,
  }
}

export function updateDimensionItemAndChildren(data, data2, id, done) {
  return (dispatch) => {
    dispatch(updateDimensionItem(data))
    dispatch(batchVisibility(data2, id, done, { notip: true }))
  }
}

export function delDimensionItem(data) {
  return {
    type: key.DELETE_DIMENSION_ITEM,
    payload: dimensionItem.PUT('/$id/delete', data),
  }
}

export function stopDimensionItem(data, done) {
  const { id, orgId } = data
  return {
    type: key.STOP_DIMENSION_ITEM,
    payload: dimensionItem.PUT('/$id/disable', { id }, { orgId }),
    done,
  }
}

export function restoreDimensionItem(data, param, done) {
  return {
    type: key.RESTORE_DIMENSION_ITEM,
    payload: dimensionItem.PUT('/$id/restore', data, param),
    done,
  }
}

export function importDimensionItem(data) {
  return {
    type: key.IMPORT_DIMENSION_ITEM,
    payload: dimensionItem.POST('/byDimensionId/$dimensionId/excel', data),
  }
}

export function exportDimensionItem(data, done) {
  return {
    type: key.EXPORT_DIMENSION_ITEM,
    payload: dimensionItem.GET('/byDimensionId/$dimensionId/excel', data),
    done,
  }
}

export function batchVisibility(params, id, done, notip) {
  const { data, orgId } = params
  return (dispatch) => {
    dispatch({
      type: key.BATCH_VISIBILITY,
      payload: dimensionItem.POST('/visibility', data, { orgId }).then((data) => {
        // dispatch(getDimensionItem({ id, orgId }))
        done()
        return data
      }),
      ...notip,
    })
  }
}

export function getDimensionItemDetail(data, done) {
  return {
    type: key.GET_DIMENSION_ITEM_DETAIL,
    payload: dimensionItem.GET('/[id]', data),
  }
}

export function getAddDimensionItemCode(data) {
  return dimensionItem.GET('/byDimensionId/$dimensionId/code', data)
}

export function getDepartByItemId(dimensionId, dimensionCode) {
  const data = {
    itemId: dimensionId,
    dimensionCode,
  }
  return dimensionItem.GET('/getDeptByItemIds', data)
}

export function createCostCenterByDept(dimensionId) {
  return dimensionItem.POST('/createCostCenterByDept', {}, { dimensionId })
}

export function getDimensionTemplate() {
  return properySet.GET('/$DIMENSION', { DIMENSION: 'DIMENSION' })
}

export function getDimensionTypeList(id) {
  return dimensionItem.GET('/$id', { id })
}

export function checkDimensionIsLeaf(ids) {
  return new Promise(async (resolve) => {
    try {
      const result = await dimensionItem.GET('/getLastNode/[id]', { id: ids })
      resolve(result.items)
    } catch (e) {
      resolve([])
    }
  })
}

export function getDimensionionList(orgId) {
  return customReport.GET('', orgId)
}

export function getEnumerateDimension() {
  return {
    type: key.ENUMERATE,
    payload: enumerate.GET(),
  }
}

// 获取枚举值
export function getEnumerateDimensionValueById(code) {
  return {
    type: key.ENUMERATE_ITEMS,
    payload: enumerateItems.GET('/byEnumCode/$code', { code }),
    code,
  }
}

// 获取枚举值
export function getEnumerateDimensionTypes() {
  return enumerate.GET()
}

// 获取对应枚举档案内容
export function getEnumerateDimensionContent(enumCode) {
  return enumerateContent.GET('/$enumCode', { enumCode })
}

// 获取自定义档案详情，返回的所属上级的名称，纯展示用
export function getDimensionDetailById(id) {
  return dimensionItem.GET('/details/$id', { id })
}
